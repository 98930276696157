import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import P from "../components/P"
import H2 from "../components/H2"
import Section from "../components/Section"
import Grid from "../components/Grid"
import Address from "../components/Footer/Address"
import H1 from "../components/H1"
import privacy from "../images/privacy.svg"
import Hero2 from "../components/Hero2"
import LinkButton from "../components/LinkButton"
import ImageBlock from "../components/ImageBlock"

const meta = {
  title: "Impressum",
  description: `Unsere Anschrift der codeflow gmbh.`,
  keywords: "impressum, anschrift, adresse",
}

const DatenschutzPage = () => {
  return (
    <Layout>
      <SEO
        title={meta.title}
        description={meta.description}
        keywords={meta.keywords}
      />

      <Hero2 height={"100vh"}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <H1>Impressum</H1>
              <LinkButton to="/impressum#read">Mehr erfahren</LinkButton>
            </Grid>
          </Grid>
        </Container>
        <ImageBlock
          alt="Webentwicklung"
          src={privacy}
          width={"640px"}
          widthSm={"960px"}
          widthMd={"800px"}
          widthLg={"50%"}
          position={{
            position: "absolute",
            bottom: "-5%",
            right: "-120px",
            zIndex: "-1",
          }}
          positionMd={{
            position: "absolute",
            bottom: "0%",
            right: "-220px",
            zIndex: "-1",
          }}
          positionLg={{
            position: "absolute",
            bottom: "0",
            right: "-220px",
            zIndex: "-1",
          }}
        />
      </Hero2>

      <Section marginBottom="0" marginBottomSm="0" marginBottomMd="0">
        <Container id="read">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <H2>Kontaktadresse</H2>

              <Address>
                codeflow gmbh <br />
                Neustadtstrasse 8a <br />
                6003 Luzern <br />
                Schweiz <br />
                info@codeflow.ch
              </Address>

              <H2>Verantwortlich für den Inhalt</H2>
              <P>Haris Bjelic</P>

              <H2>Handelsregistereintrag</H2>
              <P>
                Eingetragener Firmenname: codeflow gmbh <br />
                Nummer: CHE-404.860.392 <br />
                Gerichtstand: Luzern <br />
                Mehrwertsteuernummer: CHE-404.860.392 MWST <br />
                Stand 27 Juli 2020
              </P>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default DatenschutzPage
